import React from "react";
import { Navbar }   from "../components/navbar/Navbar";
import { Footer }   from "../components/footer/Footer";
import { AboutContent } from "../components/heroImages/AboutContent";

export const About = () => {
  return (
    <div>
      <Navbar />
      <AboutContent />
      {/* <HeroImg2 heading="ABOUT" text="I'm a friendly Front End Developer." /> */}
      {/* <AboutContent/> */}
      <Footer />
    </div>
  );
};
