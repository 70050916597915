import "./HeroImgStyles.css";
import {React} from "react";

export const HeroImg = () => {
    return (
        <>
            <div className="hero">
                <div className="mask"></div>
                <div className="content">
                    <div className="logo-transparent"></div>
                    <p style={{marginTop: 40}}> Car Accessories</p>
                </div>
            </div>

            <div className="flex-row">
                <div className="frame">
                    <div className="car-covers-difference">
                        <span className="car-covers">Premium car</span>
                        <span className="make-difference">
              {" "}
                            covers designed for ultimate protection and elegance.
            </span>
                    </div>
                    <span className="lorem-ipsum">
            Drive Confident, Protect in Style. Your car is more than just a
            vehicle, it reflects who you are. At Oenum, we offer premium car
            covers designed to offer your car unmatched style and durability.
            Crafted with high- quality car cover materials and a keen eye for
            design, our covers ensure your car remains as good as the day you
            bought it.
          </span>
                    <button className="group">
                        <div className="rectangle-3"></div>
                        <div className="group-4"></div>
                        <span className="order-now">Buy on Amazon</span>
                    </button>
                    <button className="group-6">
                        <div className="rectangle-7"></div>
                        <div className="group-8"></div>
                        <span className="order-now-9">Buy on Flipkart</span>
                    </button>
                </div>
                <div className="rectangle-a"></div>
            </div>
            <div className="flex-row-c">
                <button className="frame-b">
          <span className="defining-excellence">
            Defining Style Driving Innovation
          </span>
                    <div className="rectangle-d"></div>
                </button>
                <div className="rectangle-e"></div>
            </div>
            <span className="luxurious-car-accessories">Explore our Products</span>
            <div className="flex-row-e">
                <div className="rectangle-15-1">
                    <div className="flex-column-b-1">
                        <span className="car-covers-1">PLASMA</span>
                    </div>
                    <div className="group-16-1"/>
                </div>
                <div className="rectangle-15">
                    <div className="flex-column-b">
                        <span className="car-seat-covers">NEXUS</span>
                    </div>
                    <div className="group-16"/>
                </div>
                <div className="rectangle-17">
                    <div className="flex-column-b-18">
                        {/* <span className="span">03</span> */}
                        <span className="car-mats">RADIANCE</span>
                    </div>
                    <div className="group-19"/>
                </div>
                <div className="rectangle-1a">
                    <div className="flex-column-fa">
                        {/* <span className="span-1b">04</span> */}
                        <span className="steering-wheel-covers">CLASSIQUE</span>
                    </div>
                    <div className="group-1c"/>
                </div>
            </div>
            <span className="span-1d">What makes us memorable?</span>
            <div className="rectangle-1e">
        <span className="flex-row-dd">
          At Oenum, we understand that your car deserves the best, and
          that’s precisely what we deliver. Our covers are crafted with the
          finest car cover materials, offering the perfect blend of premium
          and functionality.
        </span>
            </div>
            <div className="rectangle-1f">
                <div className="span-20"/>
                <span className="span-21">Ready to Protect Your Car in Style?</span>
                <span className="span-22">
          Whether you park it in your driveway or store it in the
          parking area for a long time, our covers provide the
          ultimate protection with a touch of elegance.
        </span>
                <button className="group-21">
                    <div className="rectangle-3"></div>
                    <div className="group-4"></div>
                    <span className="order-now">Buy on Amazon</span>
                </button>
                <button className="group-22">
                    <div className="rectangle-7"></div>
                    <div className="group-8"></div>
                    <span className="order-now-9">Buy on Flipkart</span>
                </button>
            </div>
            {/* <span className="become-a-part">Become a part of our community</span>
      <div className="flex-row-afe">
        <div className="rectangle-23">
          <div className="vector" />
        </div>
        <div className="rectangle-24">
          <div className="vector-25" />
        </div>
        <div className="rectangle-26">
          <div className="vector-27" />
        </div>
        <div className="rectangle-28">
          <div className="vector-29" />
        </div>
      </div>
      <div className="flex-row-a">
        <div className="rectangle-2a">
          <div className="vector-2b" />
        </div>
        <div className="rectangle-2c">
          <div className="vector-2d" />
        </div>
        <div className="rectangle-2e">
          <div className="vector-2f" />
        </div>
        <div className="rectangle-30">
          <div className="vector-31" />
        </div>
      </div> */}
        </>
    );
};
