import "./FooterStyles.css";
import React from "react";

export const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="footer-container">
                    <div className="container  left ">
                        <div className="inquiry">Inquiry</div>
                        <form action="#">
                            <div className="form-row">
                                <div className="input-data">
                                    <input type="text"/>
                                    <div className="underline"></div>
                                    <label for="">First Name</label>
                                </div>
                                <div className="input-data">
                                    <input type="text"/>
                                    <div className="underline"></div>
                                    <label for="">Email</label>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="input-data">
                                    <input type="text" placeholder="Write your message..."/>
                                    <div className="underline"></div>
                                    <label for="">Message</label>
                                </div>
                            </div>
                            <div className="submitdiv">
                                <button className="submitbtn">Send</button>
                            </div>
                        </form>
                    </div>

                    <div className="container middle">
                        <a href="https://www.linkedin.com/company/oenum/" className="vector-37" target="_blank"
                           rel="noopener noreferrer"></a>
                        <a href="https://www.instagram.com/drive.oenum?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                           className="vector-38" target="_blank" rel="noopener noreferrer"></a>
                    </div>
                    <div className="right">
                        <div className="reach">Reach Us</div>

                        <div className="location">
                            <div>
                                <p>Property No.08, First Floor</p>
                                <p>J-Block,Mela Ram Farm</p>
                                <p>Mandoli, Delhi-110093</p>
                            </div>
                        </div>

                        <div className="phone">
                            <p></p>
                            <p>+91-011-40600901/24/25</p>
                            <p>+91-9810102189</p>
                            <p></p>
                        </div>
                        <div className="email">
                            <p>customercare.oenum@gmail.com</p>
                            <div className="social"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
