import React from "react";
import { Navbar } from "../components/navbar/Navbar";
import { Footer } from "../components/footer/Footer";

import { Product } from "../components/products/Product";

export const Products = () => {
  return (
    <div>
      <Navbar />
      {/* <HeroImg2 heading="PROJECTS" text="Some Of My Recent Works." />
      <PriceCardList />
  <Work />  */}
      <Product />
      <Footer />
    </div>
  );
};
