import React, {useRef} from "react";
import "./ProductStyle.css";

export const Product = () => {
    const navRef = useRef();
    const rectangleRef = useRef();

    const handleNav = (direction) => {
        const scrollAmount = rectangleRef.current.offsetWidth;
        const {scrollLeft, scrollWidth, clientWidth} = navRef.current;

        if (direction === "left") {
            if (scrollLeft === 0) {
                navRef.current.scrollLeft = scrollWidth - clientWidth;
            } else {
                navRef.current.scrollLeft -= scrollAmount - 18;
            }
        } else {
            if (scrollLeft + clientWidth >= scrollWidth) {
                navRef.current.scrollLeft = 0;
            } else {
                navRef.current.scrollLeft += scrollAmount + 18;
            }
        }
    };
    return (
        <>
            <div className="herop">
                <div className="maskp"></div>
                <div className="contentp">
                    {/*<div className="logo-transparent"></div>*/}
                    <p><span style={{fontSize: "48px", fontWeight: "600"}}>PLASMA</span>
                        <br/>
                        <br/>
                        <span style={{fontStyle: "italic"}}>Elegance Meets Ultimate Protection</span>
                        <br/>
                        Plasma, the ultimate premium car body cover designed to give
                        top-tier care and appearance. Comes in variety of colour options
                        and fits a variety of car models precisely.
                        <br/>
                        <br/>
                        Whether your car is parked in your driveway or out in the open,
                        the tailored fit ensures full coverage, protecting it from
                        environmental risks while adding a little style.
                    </p>
                </div>
            </div>
            <div className="main-container-product">
                {/*<div className="flex-row-product">*/}
                {/*    <div className="rectangle-product"></div>*/}
                {/*    <span className="premium-car-covers-product">*/}
                {/*        Premium Car Covers{" "}*/}
                {/*      </span>*/}
                {/*    <span className="our-commitment-to-quality-product">*/}
                {/*            Our commitment to quality is evident through our ISO22000:2005*/}
                {/*            certification and HALAL approval. Withinour cutting-edge application*/}
                {/*            lab, equipped with state-ofthe-art machines, we meticulously produce*/}
                {/*            liquid and dryflavors, emulsions, and food colors.*/}
                {/*          </span>*/}
                {/*    <div className="rectangle-1-product">*/}
                {/*        <div className="group-product"/>*/}
                {/*        <span className="order-now-at-product">ORDER NOW AT</span>*/}
                {/*        <div className="group-2-product"/>*/}
                {/*    </div>*/}
                {/*    <div className="rectangle-3-333-product">*/}
                {/*        <div className="group-4-product"/>*/}
                {/*        <span className="order-now-product">ORDER NOW AT</span>*/}
                {/*        <div className="flipkart-logo-black-product"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="flex-row-aff-product" ref={navRef}>
                    <div className="rectangle-5-product" ref={rectangleRef}/>
                    <div className="rectangle-6-product" ref={rectangleRef}/>
                    <div className="rectangle-7-product" ref={rectangleRef}/>
                    <div className="rectangle-5-product" ref={rectangleRef}/>
                    <div className="rectangle-6-product" ref={rectangleRef}/>
                    <div className="rectangle-7-product" ref={rectangleRef}/>
                </div>
                <div className="flex-row-b-product">
                    <button
                        className="vector-product"
                        onClick={() => handleNav("left")}
                    ></button>
                    <button
                        className="vector-8-product"
                        onClick={() => handleNav("right")}
                    ></button>
                </div>
                <div className="flex-row-e-product">
                    <div className="rectangle-9-product"/>
                    <div>
                        <span className="weatherproof-uv-dust-resistant-product">
                          UV, Dust or Rain, nothing comes close to this
                        </span>
                        <span className="quality-iso-halal-product">
                            Advanced water-repellent technology to protect against
                            rain and moisture, keeping your car dry and rust-free.
                            <br/>
                            <br/>
                            Its dustproof properties shield your vehicle from dirt,
                            debris, and pollutants, ensuring a spotless exterior.
                            <br/>
                            <br/>
                            Additionally, the UV-resistant material blocks harmful sun
                            rays, preventing paint fading and interior damage,
                            making it an all-season essential for your car's
                            protection.
                        </span>
                    </div>
                </div>
                <div className="rectangle-d-product">
                    <span className="a-luxury-for-your-car-product">
                        A choice you won’t regret
                        <br/>
                        <br/>
                    <p className="highly-qualified-and-experienced-product">
                        Simple to maintain and completely washable, keeping
                        the protective properties after every cleaning.
                        <br/>
                        <br/>
                        Designed for easy use, it provides quick, seamless
                        installation, perfect for those on the go.
                  </p>
                    </span>
                </div>
                <span className="other-products-you-might-like-product">
                  Other products you might like
                </span>
                <div className="flex-row-ee">
                    {/*<div className="rectangle-15-1">*/}
                    {/*    <div className="flex-column-b-1">*/}
                    {/*        <span className="car-covers-1">PLASMA</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="group-16-1"/>*/}
                    {/*</div>*/}
                    <div className="rectangle-15">
                        <div className="flex-column-b">
                            <span className="car-seat-covers">NEXUS</span>
                        </div>
                        <div className="group-16"/>
                    </div>
                    <div className="rectangle-17">
                        <div className="flex-column-b-18">
                            {/* <span className="span">03</span> */}
                            <span className="car-mats">RADIANCE</span>
                        </div>
                        <div className="group-19"/>
                    </div>
                    <div className="rectangle-1a">
                        <div className="flex-column-fa">
                            {/* <span className="span-1b">04</span> */}
                            <span className="steering-wheel-covers">CLASSIQUE</span>
                        </div>
                        <div className="group-1c"/>
                    </div>
                </div>
                {/*<div className="flex-row-c-product">*/}
                {/*    <div className="rectangle-e-product">*/}
                {/*        <span className="text-17-product">02</span>*/}
                {/*        <span className="car-seat-covers-product">Car Seat Covers</span>*/}
                {/*    </div>*/}
                {/*    <div className="rectangle-f-product">*/}
                {/*        <span className="text-19-product">03</span>*/}
                {/*        <span className="car-mats-product">Car Mats</span>*/}
                {/*    </div>*/}
                {/*    <div className="rectangle-10-product">*/}
                {/*        <span className="text-1b-product">04</span>*/}
                {/*        <span className="steering-wheel-covers-product">*/}
                {/*          Steering Wheel Covers*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};
