import React from "react";
import { Navbar } from "../components/navbar/Navbar";
import { Footer } from "../components/footer/Footer";
import { HeroImg2 } from "../components/heroImages/HeroImg2";
 import { ContactForm } from "../components/contact/ContactForm";
import { HeroImg } from "../components/heroImages/HeroImg";


export const Contact = () => {
  
  return (
    <div>
      <Navbar />
      <Footer />
     
    </div>
  );
};

