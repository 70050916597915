import React from "react";
import "./AboutContent.css";
import myImage from "../../assets/about-head.png";
import myImage3 from "../../assets/a4.jpg";
import myImage4 from "../../assets/a5.png";
import myImage5 from "../../assets/a6.png";

export const AboutContent = () => {
  return (
      <>
        <div className="header-top">
          <video className="video-a-about" autoPlay loop muted playsInline>
            <source src={require("../../assets/prod.mp4")} type="video/mp4"/>
            <img src={myImage} className="image-a-about" alt="Fallback image"/>
            <p className="video-fallback-message">Your browser does not support the video tag.</p>
          </video>
        </div>
        <div className="flex-row-aac-about">
          <img src={myImage3} className="rectangle-3-about" alt="test"/>
          <div className="heading2">
            <span className="our-mission-about">Story Behind Oenum</span>
            <span className="our-commitment-quality-about">
            Our love for cars is more than just a passion, it’s a way of life.
            At Oenum, we understand the bond between a car owner and their
            vehicle. That’s why we’ve dedicated ourselves to crafting car covers
            that protect and honor that connection. With every product, we bring
            our expertise, precision, and care to protect your car.
          </span>
          </div>
        </div>

        <div className="flex-row-aac-about">
          <div className="heading3">
            <span className="our-mission-about">Commitment to Quality</span>
            <span className="our-commitment-quality-about1">
            We source only the finest materials and use cutting- edge technology
            to ensure that each cover meets the highest standards of durability
            and design.
          </span>
          </div>
          <img src={myImage4} className="rectangle-4-about" alt="test"/>
        </div>
        <div className="flex-row-aac-about1">
          <img src={myImage5} className="rectangle-5-about" alt="test"/>
          <div className="heading2">
            <span className="our-mission-about">Our Team</span>
            <span className="our-commitment-quality-about">
              We founded Oenum to turn our passion for cars into something
              meaningful. As car enthusiasts with experience in engineering,
              marketing, and business, we observed car owners craved style,
              durability, and products that  celebrated their connection with
              their vehicles.
              <br/>
              <br/>
              At Oenum, we blend modern technology with handcrafted
              craftsmanship to create premium car accessories that
              safeguard memories, elevate experiences, and make every
              ride feel unique.
              <br/>
              <br/>
              For us, it is not just about products, it is about ensuring every
              car gets the care it deserves.
              <br/>
              <br/>
              <strong>-Vibhor, Vansh and Divyansh</strong>
          </span>
          </div>
        </div>
      </>
  );
};
